import {
  AllProductsQuery,
  StrapiProductCardFragment
} from 'gatsby/graphqlTypes';
import { BodyText, Heading } from 'components/atoms/typography';
import React, { useEffect, useRef, useState } from 'react';

import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import ErrorMessage from 'components/molecules/actions/PageErrorMessage';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';
import NewsletterDiscountClaimed from 'components/molecules/marketing/NewsletterDiscountClaimed';
import PageContentLayout from 'components/layouts/PageContentLayout';
import PageLoading from 'components/molecules/actions/PageLoading';
import { assertUnreachable } from 'lib/util';
import asyncValidate from 'workers/validation';
import generateId from 'lib/id';
import { graphql } from 'gatsby';
import { invoicedDiscountStore, updateCart } from 'state/stores/checkout';
import { loggers } from 'lib/log';
import { useQueryParamsObj } from 'lib/navigation/hooks';
import { useReadable } from 'lib/react-svelte/reactifyStores';
import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { makeMutationPromise } from '@svelte/service/backend/queryStore';
import useAppEventDispatcher from 'lib/events/hooks';

const log = loggers.ui;

export { Head } from 'components/atoms/Head';
export type Props = AppPageProps<AllProductsQuery>;

export const query = graphql`
  query AllProducts {
    c: strapiProductCategory(strapiId: { eq: 22 }) {
      products: recursiveProducts {
        ...StrapiProductCard
      }
    }
  }
`;

const SuccessMessage: React.FC = () => {
  return (
    <>
      <Heading level={1}>Done!</Heading>
      <BodyText measure="measure-narrow" className="text-center">
        Thank you for confirming your newsletter subscription.
      </BodyText>
    </>
  );
};

const enum OpState {
  INVALID_TOKENS,
  LOADING,
  SENT,
  CONFIRMED_NEWSLETTER,
  ERROR
}

type ContentState = OpState | GqlSchemaWorker.ConfirmNewsletterDiscountMutation;

const ContentOfConfirmState: React.FC<{
  state: ContentState;
  products: StrapiProductCardFragment[];
}> = ({ products, state }) => {
  const discountState = useReadable(invoicedDiscountStore);

  if (typeof state === 'number') {
    switch (state) {
      case OpState.INVALID_TOKENS:
        return (
          <ErrorMessage message="Some information is missing in order to show this page correctly. Please contact us for more info." />
        );
      case OpState.LOADING:
        return <PageLoading message="Loading" />;
      case OpState.SENT:
        return <PageLoading message="Confirming subscription" />;
      case OpState.CONFIRMED_NEWSLETTER:
        return <SuccessMessage />;
      case OpState.ERROR:
        return (
          <ErrorMessage message="We're unable to confirm your subscription at the moment. Please try again later." />
        );
      default:
        assertUnreachable(state);
    }
  }

  const { claimDiscount } = state;
  const discount =
    claimDiscount.__typename === 'ClaimedDiscount'
      ? claimDiscount.cart.discount
      : discountState;

  if (discount) {
    if (discount.amount.__typename === 'DiscountPercentage') {
      return (
        <NewsletterDiscountClaimed
          products={products}
          percentage={discount.amount.value}
        />
      );
    }

    throw Error('Discount type not implemented');
  }

  const error = new Error('Unable to handle discount');
  loggers.ui.error(error, { discountState, contentState: state, products });
  throw error;
};

type ConfirmationParams = {
  confirmationToken: string;
  email: string;
};

type ConfirmationDiscountParams = ConfirmationParams & {
  lt: string;
  uid: string;
  did: string;
};

const NewsletterActionsPage: React.FC<Props> = ({ data, pageContext }) => {
  const params = useQueryParamsObj();
  const [opState, setOpState] = useState<ContentState>(OpState.LOADING);
  const hasRun = useRef(false);
  const worker = useGraphqlWorker();
  const eventDispatcher = useAppEventDispatcher();

  useEffect(() => {
    /**
     * Don't re-run action
     * if already run during this lifecycle.
     *
     * This guard is required for when navigating away, as tokenUid may be undefined
     */
    if (hasRun.current) {
      return;
    }

    Promise.all([
      asyncValidate({
        id: generateId(),
        schemaName: 'confirmNewsletterWithDiscountSchema',
        values: params
      }),
      asyncValidate({
        id: generateId(),
        schemaName: 'confirmNewsletterSchema',
        values: params
      })
    ]).then(async ([confirmDiscountErrors, confirmErrors]) => {
      if (!confirmDiscountErrors.errors) {
        // handle discount claim
        const { confirmationToken, email, did, lt } =
          params as ConfirmationDiscountParams;

        const output = await makeMutationPromise({
          key: 'confirmNewsletterDiscount',
          variables: {
            confirmation: {
              email,
              token: confirmationToken
            },
            claim: {
              discountId: did,
              email
            },
            token: lt
          },
          worker
        });

        if (output.error || !output.data) {
          loggers.ui.error(new Error('confirmNewsletterDiscount query error'), {
            error: output.error
          });
          setOpState(OpState.ERROR);
        } else if (output.data) {
          const kind = output.data.claimDiscount.__typename;
          switch (kind) {
            case 'ClaimedDiscount':
              updateCart(eventDispatcher, output.data.claimDiscount.cart);
              break;
            case 'ClaimDiscountOptions':
              log.error(new Error('Claim discount conflict detected'), {
                data: output.data
              });
              break;
            case 'ClaimNotChanged':
              break;
            default:
              assertUnreachable(kind);
          }

          setOpState(output.data);
        }

        return;
      }

      if (!confirmErrors.errors) {
        // handle plain newsletter confirmation
        const { confirmationToken, email } = params as ConfirmationParams;

        setOpState(OpState.SENT);

        const { data, error } = await makeMutationPromise({
          key: 'confirmNewsletterSubscription',
          variables: {
            confirmation: {
              email,
              token: confirmationToken
            }
          },
          worker
        });

        if (error || !data) {
          loggers.ui.error(
            new Error('confirmNewsletterSubscription query error'),
            { error }
          );
          setOpState(OpState.ERROR);
        } else {
          setOpState(OpState.CONFIRMED_NEWSLETTER);
        }

        return;
      }

      setOpState(OpState.INVALID_TOKENS);
      loggers.ui.error(
        new Error('Invalid query parameters on NewsletterActionsPage'),
        { params, confirmDiscountErrors, confirmErrors }
      );
    });

    hasRun.current = true;
  }, []);

  return (
    <PageContentLayout
      footerNavigation={pageContext.footerNavigation}
      withSubscriptionBanner={false}
      className="flex flex-col items-center justify-center"
    >
      <Container className="text-center">
        <ContentOfConfirmState
          state={opState}
          products={data.c?.products as StrapiProductCardFragment[]}
        />
      </Container>
    </PageContentLayout>
  );
};

export default NewsletterActionsPage;
