/**
 * By performing Yup validation in a worker
 * we remove all script parsing from DOM thread
 * while still loading eagerly
 */

import type { ValidationWorkerMessage, WorkerValidationOutput } from './types';

const worker =
  typeof window !== 'undefined'
    ? new Worker(
        /* webpackChunkName: "validation-leg" */ new URL(
          './worker.ts',
          import.meta.url
        )
      )
    : undefined;

export default function asyncValidate(
  message: ValidationWorkerMessage
): Promise<WorkerValidationOutput> {
  if (worker) {
    return new Promise(resolve => {
      const listener = ({ data }: MessageEvent<WorkerValidationOutput>) => {
        if (data) {
          if (data.id === message.id) {
            resolve(data);
            worker.removeEventListener('message', listener);
          }
        }
      };

      worker.addEventListener('message', listener);
      worker.postMessage(message);
    });
  } else {
    throw Error('No validation worker');
  }
}
