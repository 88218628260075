import { BodyText, Heading, TextSize } from 'components/atoms/typography';

import Balancer from 'react-wrap-balancer';
import { ProductList } from 'components/organisms/product-list/List';
import React from 'react';
import { StrapiProductCardFragment } from 'gatsby/graphqlTypes';

type NewsletterDiscountClaimed = Readonly<{
  firstName?: string;
  percentage: number;
  products: StrapiProductCardFragment[];
}>;

// TODO: create a newsletter actions content type in Strapi
// - get image
// - get text
const NewsletterDiscountClaimed: React.FC<NewsletterDiscountClaimed> = ({
  firstName,
  percentage,
  products
}) => {
  return (
    <div className="flex w-full flex-col items-center justify-center pb-rhythm6">
      <Heading level={1} size={TextSize.DoublePica}>
        <Balancer>Welcome to our community</Balancer>
      </Heading>

      {percentage % 10 === 0 ? (
        <div className="mb-rhythm2 flex h-rhythm5 items-center justify-center text-[4rem] text-primary">
          <span>{percentage / 10}</span>
          <img
            className="inline-block h-full"
            src="https://cms-static.disruptor.london/earth_sustainability_5ee38ac0c5.svg"
          />
          <span>%</span>
        </div>
      ) : (
        <Heading level={2}>You have a {percentage}% discount.</Heading>
      )}
      <BodyText className="text-left">
        {firstName ? `${firstName}, we` : 'We'} can only solve the climate
        crisis together. Thank you so much for being part of the solution.
      </BodyText>
      <BodyText className="text-left">
        We thank you with a {percentage}% discount on your next purchase. Your
        newsletter subscription is now confirmed and your discount is already
        applied. Just shop around like you normally would. No voucher codes
        required.
      </BodyText>

      <ProductList
        cardSize="small"
        className="pt-rhythm4"
        products={products}
      />
    </div>
  );
};

export default NewsletterDiscountClaimed;
